import {
  GENERATE_PDF_APAC,
  GET_CID10,
  GET_HIGH_COMPLEXITY_PROCEDURES,
} from "services/api";
import AppContext from "services/context";

import validationSchema from "./validationSchema";

import establishmentSectionValidationSchema from "components/EstablishmentFormSection/validationSchema";
import patientSectionValidationSchema from "components/PatientFormSection/validationSchema";
import professionalSectionValidationSchema from "components/ProfessionalFormSection/validationSchema";

import { useLazyQuery, useMutation } from "@apollo/client";
import Button from "components/Button";
import { establishmentSectionDefaultRequiredFields } from "components/EstablishmentFormSection";
import EstablishmentFormSection from "components/EstablishmentFormSection";
import Input from "components/Input";
import FormPageTemplate from "components/LYT_FormPage";
import PatientFormSection, {
  patientSectionInitialValues,
} from "components/PatientFormSection";
import { professionalSectionDefaultRequiredFields } from "components/ProfessionalFormSection";
import ProfessionalFormSection from "components/ProfessionalFormSection";
import Select from "components/Select";
import Tip from "components/Tip";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useGeneratePdfApiResponse from "services/hooks/useGeneratePdfApiResponse";
import useLGPDTerms from "services/hooks/useLGPDTerms";
import usePatientParams from "services/hooks/usePatientParams";

const initialValues = {
    establishmentName: "USF Sede II - Água Fria",
    establishmentCNES: "5210143",
    professionalUf: "BA",
  },
  patientSectionRequiredFields = {
    name: true,
    motherName: true,
    dob: true,
    sex: true,
    cns: false,
    address: {},
  },
  patientSectionVisibleFields = {
    address: {
      cep: true,
      street: true,
      number: true,
      complement: true,
      neighborhood: true,
      city: true,
      uf: true,
      country: true,
    },
  };

export default () => {
  const [generatePdfAPAC, { loading, error, data }] =
      useMutation(GENERATE_PDF_APAC),
    formik = useFormik({
      initialValues: usePatientParams({
        ...patientSectionInitialValues,
        ...initialValues,
      }),
      initialTouched: {},
      validationSchema: validationSchema
        .concat(patientSectionValidationSchema(patientSectionRequiredFields))
        .concat(
          professionalSectionValidationSchema(
            professionalSectionDefaultRequiredFields
          )
        )
        .concat(
          establishmentSectionValidationSchema(
            establishmentSectionDefaultRequiredFields
          )
        ),
      onSubmit: (values) => {
        setLoading(true);
        const variables = {
          ...values,
          patientAddressUf: values.patientAddressUf || null,
          mainProcedure: {
            code: values.mainProcedure.code,
            name: values.mainProcedure.description,
            quantity: 1,
          },
          secondariesProcedures: values.secondariesProcedures
            ? [
                ...values.secondariesProcedures.map(
                  ({ code, description: name }) => ({
                    code,
                    name,
                    quantity: 1,
                  })
                ),
              ]
            : null,
          anamnesisCid10Code: values.anamnesisCid10.code,
          anamnesisCid10Description: values.anamnesisCid10.description,
          anamnesisSecondaryCid10Code: values.anamnesisSecondaryCid10?.code,
          anamnesisAssociatedCauseCid10Code:
            values.anamnesisAssociatedCauseCid10?.code,
        };
        console.log(variables);
        generatePdfAPAC({
          variables,
        });
      },
    }),
    { setLoading } = useContext(AppContext),
    [getProcedures] = useLazyQuery(GET_HIGH_COMPLEXITY_PROCEDURES),
    [getCid10] = useLazyQuery(GET_CID10),
    filterProcedures = async (search) => {
      const rtn = await getProcedures({ variables: { search } });
      return rtn.data?.highComplexityProcedures.map((p) => ({
        label: `${p.code} - ${p.name}`,
        code: p.code,
        description: p.name,
        value: p.id,
      }));
    },
    filterCid10 = async (search) => {
      const rtn = await getCid10({ variables: { search } });
      return rtn.data?.cid10.map((p) => ({
        label: `${p.code} - ${p.description}`,
        code: p.code,
        description: p.description,
        value: p.code,
      }));
    };

  useGeneratePdfApiResponse("generatePdfApac", { data, error, loading });
  useLGPDTerms(formik.values);

  return (
    <FormPageTemplate
      metaTags={{
        title:
          "Ferramenta de Documentos Médicos Online - Imprimir APAC Grátis!",
        description:
          "🚀 Prático, rápido, online e grátis! Agora ficou muito mais fácil Gerar sua APAC com essa ferramenta online, gratuita, de fácil uso e acesso! Agora não tem mais desculpa para deixar documento para o coleguinha do próximo plantão!",
        imagePath: "apac.jpg",
        keywords:
          "imprimir, apac, sus, sistema de saude, saude, tomografia, tc de cranio, pdf, apac pdf",
      }}
    >
      <main>
        <header>
          <h1>
            <p>Gerar</p>APAC <Link to="/">[Ver outros Documentos]</Link>
            <p>Autorização de Procedimentos Ambulaoriais - Versão Antiga</p>
          </h1>
          <p className="warning">
            Esse documento ainda está em teste e pode apresentar alguns bugs
          </p>
        </header>
        <Tip href="https://chromewebstore.google.com/detail/esus-pec-plugin-chrome/efcfpioipajohmjkbckmedhajbfgddog?hl=pt-BR">
          <p>
            Você utiliza o PEC (Sistema do SUS) na sua Unidade de Saúde? Baixe o
            Plugin para o Google Chrome e aproveite gratuitamente os benefícios
            do preenchimento automatizado de formulários. Assim você ganha ainda
            mais tempo!
          </p>
        </Tip>
        <form onSubmit={formik.handleSubmit}>
          <section>
            <header>
              <h2>Dados do Solicitante</h2>
            </header>
            <ProfessionalFormSection formik={formik} />
          </section>
          <section>
            <header>
              <h2>Dados do Estabelecimento</h2>
            </header>
            <EstablishmentFormSection formik={formik} />
          </section>
          <section>
            <header>
              <h2>Dados do Paciente</h2>
            </header>
            <PatientFormSection
              formik={formik}
              requiredFields={patientSectionRequiredFields}
              visibleFields={patientSectionVisibleFields}
            />
          </section>
          <section>
            <header>
              <h2>Dados do Procedimento</h2>
            </header>
            <section>
              <Select
                placeholder="Procedimento Principal"
                name="mainProcedure"
                formik={formik}
                options={filterProcedures}
                required
                async
              />
              <Select
                label="Procedimentos Secundários"
                name="secondariesProcedures"
                formik={formik}
                options={filterProcedures}
                isOptionDisabled={() =>
                  formik.values.secondariesProcedures?.length >= 5
                }
                async
                isMulti
              />
            </section>
          </section>
          <section>
            <header>
              <h2>Dados da Anamnese</h2>
            </header>
            <Select
              label="Diagnóstico"
              name="anamnesisCid10"
              formik={formik}
              options={filterCid10}
              async
              required
            />
            <Select
              label="Diagnóstico Secundário"
              name="anamnesisSecondaryCid10"
              formik={formik}
              options={filterCid10}
              async
            />
            <Select
              label="Causa Associada"
              name="anamnesisAssociatedCauseCid10"
              formik={formik}
              options={filterCid10}
              async
            />
            <Input
              label="Observações"
              name="anamnesisObservations"
              required={true}
              formik={formik}
              multiline
            />
          </section>
          {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
          <Button type="submit">Enviar</Button>
        </form>
      </main>
    </FormPageTemplate>
  );
};
